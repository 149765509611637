import React, { useState } from "react";
import Apply from "../../Pages/Enrollment/apply";
import Confirmation from "../../Pages/Enrollment/confirmation";
import CourseConfirmation from "../../Pages/Enrollment/course-confirmation";
import CourseSelection from "../../Pages/Enrollment/course-selection";
import StudentInfo from "../../Pages/Enrollment/student-info";
import { message, Modal, Row, Col } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import elementarySchoolData from "../../assets/data/grade.data.json";
import highSchoolData from "../../assets/data/high-school-course.data.json";
import middleSchoolData from "../../assets/data/middle-school-course.data.json";
import RegistrationSuccessScreen from "./registration-success";
import moment from "moment";
import EnrollmentSuccessScreen from "./enrollment-success";
import { makePayment } from "../../components/Payments/Razorpay";

const Enrollment = () => {
  const [step, setStepProcess] = useState(1);
  const [groupId, setGradeGroup] = useState(1);
  const [courseList, setCourseList] = useState([]);
  const [selectedCourseList, setSelectedCourse] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [paymentResponse, setPaymentResponse] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [show, setShow] = useState(false);

  const stepData = [
    {
      name: "Apply",
    },
    {
      name: "Confirmation",
    },
    {
      name: "Course Selection",
    },
    {
      name: "Course Confirmation",
    },
    {
      name: "Registration",
    },
    {
      name: "Admission",
    },
  ];

  const TYPE = {
    CORE: "core",
    OPTIONAL: "optional",
  };

  const GRADE_GROUP = {
    ElementrySchool: 1,
    MiddleSchool: 2,
    HighSchool: 3,
  };

  const setStep = (step) => {
    window.scrollTo(0, 0);

    if(step===2){
      resetCourseSelection();
    }
    
    setStepProcess(step);
  };

  const resetCourseSelection = () => {
    setCourseList((prevCourseList) =>
      prevCourseList.map((grade) => ({
        ...grade,
        course: grade.course.map((courseItem) => ({
          ...courseItem,
          checked: false,
          segmentId: "Select Segment",
        })),
      }))
    );
    setSelectedCourse([]);
  };

  const confirmCourse = () => {
    setShow(false);
    setStep((step) => step + 1);
  };

  const changeGroup = (groupId) => {
    setGradeGroup(groupId);
    if (groupId === GRADE_GROUP.ElementrySchool) {
      setCourseList(elementarySchoolData);
    } else if (groupId === GRADE_GROUP.HighSchool) {
      setCourseList(highSchoolData);
    } else {
      setCourseList(middleSchoolData);
    }
    setStep((step) => step + 1);
  };

  const handleNext = () => {
    //Validation for elementry course (Pre-K to 5th)
    if (step === 3 && groupId === GRADE_GROUP.ElementrySchool) {
      const filteredList = courseList.filter(function (grade) {
        return grade.course.find(
          (course) => course.checked === true && course.type === TYPE.CORE
        );
      });

      if (!filteredList.length) {
        message.warning("Please select atleast one core course");
        return false;
      }

      const selectedCourseData = [];

      filteredList.forEach((grade) => {
        grade.course.forEach((course) => {
          if (course.checked) {
            selectedCourseData.push({
              id: course.id,
              key: course.key,
              title: course.title,
              segmentId: course.segmentId,
              cost: 75 * (course.segmentId === 3 ? 2 : 1),
              isWaiver: false,
            });
          }
        });
      });
      setSelectedCourse(selectedCourseData);
    }

    //Validation for high/middle school course
    if (step === 3 && groupId > GRADE_GROUP.ElementrySchool) {
      const filteredList = courseList.filter(function (grade) {
        return grade.course.find((course) => course.checked === true);
      });
      if (!filteredList.length) {
        message.warning("Please select atleast one course");
        return false;
      }

      const selectedCourseData = [];

      filteredList.forEach((grade) => {
        grade.course.forEach((course) => {
          if (course.checked) {
            selectedCourseData.push({
              id: course.id,
              key: course.key,
              title: course.title,
              semesterId: 1,
              preferredStartDate: moment().format("MM/DD/YYYY"),
              earliestStartDate: moment().format("MM/DD/YYYY"),
              cost: 75 * (course.semesterId === 3 ? 2 : 1),
              credit: 0.5 * (course.semesterId === 3 ? 2 : 1),
              isWaiver: false,
            });
          }
        });
      });
      setSelectedCourse(selectedCourseData);
    }

    if (step === 4) {
      setShow(true);
      return false;
    }

    window.scrollTo(0, 0);
    setStep((step) => step + 1);
  };

  if(paymentResponse.length > 0){
    console.log("Payment Response:", paymentResponse)
  }

  return (
    <div className="page-wrapper">
      <section className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="sec-title centered">
              <h1>Enrollment</h1>
              <div className="separator"></div>
              <div className="stepper-wrapper p-3">
                {stepData.map((item, index) => (
                  <div
                    className={`stepper-item ${
                      step === index + 1
                        ? "active"
                        : step > index + 1
                        ? "completed"
                        : ""
                    }`}
                  >
                    <div className="step-counter">
                      {step > index + 1 ? <CheckOutlined /> : index + 1}
                    </div>
                    <div className="step-name">{item.name}</div>
                  </div>
                ))}
              </div>

              <div className="widget widget_block">
                <div className="inner-container">
                  <div className="row clearfix">
                    <div className="content-column col-lg-12 col-md-12 col-sm-12 px-lg-0">
                      <div className="inner-column p-2">
                        {step === 1 ? (
                          <Apply
                            setStep={setStep}
                            setGradeGroup={changeGroup}
                            courseList={courseList}
                          />
                        ) : null}

                        {step === 2 ? <Confirmation setStep={setStep} groupId={groupId} /> : null}

                        {step === 3 ? (
                          <CourseSelection
                            groupId={groupId}
                            courseList={courseList}
                            setCourse={setCourseList}
                          />
                        ) : null}

                        {step === 4 ? (
                          <CourseConfirmation
                            groupId={groupId}
                            selectedCourseList={selectedCourseList}
                            setSelectedCourse={setSelectedCourse}
                            totalCost={totalCost}
                            setTotalCost={setTotalCost}
                          />
                        ) : null}
                        {step === 5 ? (
                          <StudentInfo
                            selectedCourseList={selectedCourseList}
                            setStep={setStep}
                            step={step}
                            studentData={studentData}
                            setStudentData={setStudentData}
                          />
                        ) : null}
                        {step === 6 ? <RegistrationSuccessScreen /> : null}
                        {step === 7 ? <EnrollmentSuccessScreen /> : null}
                      </div>

                      <Row gutter={24} justify="end" className="text-right ">
                        <Col
                          span={4}
                          xs={24}
                          sm={24}
                          md={8}
                          lg={8}
                          xl={8}
                          xxl={8}
                        >
                          <div className="col-lg-12 col-md-12 text-align-right">
                            {step > 1 && step <= 4 ? (
                              <button
                                type="button"
                                className="btn clearfix login-btn width-15 "
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  setStep(step - 1);
                                  localStorage.clear();
                                }}
                              >
                                <span className="btn-wrap ">
                                  <span className="text-one">Back</span>
                                  <span className="text-two">Back</span>
                                </span>
                              </button>
                            ) : null}

                            {step === 3 || step === 4 ? (
                              <button
                                type="button"
                                className="btn clearfix login-btn width-15"
                                data-bs-dismiss="modal"
                                onClick={handleNext}
                              >
                                <span className="btn-wrap">
                                  <span className="text-one">Next</span>
                                  <span className="text-two">Next</span>
                                </span>
                              </button>
                            ) : null}

                            {step === 6 ? (
                              <button
                                type="button"
                                className="btn clearfix login-btn width-15"
                                data-bs-dismiss="modal"
                                onClick={() => makePayment(studentData, totalCost,setPaymentResponse, setStepProcess)}
                              >
                                <span className="btn-wrap">
                                  <span className="text-one">Pay Now</span>
                                  <span className="text-two">Pay Now</span>
                                </span>
                              </button>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="CONFIRM PREREQUISITES"
          className="modalStyle "
          open={show}
          maskClosable={false}
          closable={false}
          width={500}
          footer={null}
        >
          <strong>
            I understand following course(s) has prerequisite(s) and I
            acknowledge that I have met these requirements.
          </strong>{" "}
          <br /> <br />
          <ul>
            {selectedCourseList.map((course, index) => (
              <li>
                <strong>{index + 1} - </strong> {course.title}
              </li>
            ))}
          </ul>
          <div className="row text-align-right">
            <div className="col-lg-12 col-md-12">
              <button
                type="button"
                className="btn btn-three login-btn"
                data-bs-dismiss="modal"
                onClick={() => setShow(false)}
              >
                <span className="btn-wrap">
                  <span className="text-one">NO</span>
                  <span className="text-two">NO</span>
                </span>
              </button>
              <button
                type="button"
                className="btn clearfix login-btn width-15"
                data-bs-dismiss="modal"
                onClick={() => confirmCourse()}
              >
                <span className="btn-wrap">
                  <span className="text-one">YES</span>
                  <span className="text-two">YES</span>
                </span>
              </button>
            </div>
          </div>
        </Modal>
      </section>
    </div>
  );
};

export default Enrollment;
