import React, { useState, useEffect } from "react";
import { Input, Row, Col, Form, Select, DatePicker, Popconfirm, Button, Table, message, Card } from "antd";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import gradeData from "../../assets/data/grade.data.json";
import genderList from "../../assets/data/gender.data.json";
import documentListData from "../../assets/data/document-type.data.json";
import relationshipListData from "../../assets/data/relationship.data.json";
import ethnicityListData from "../../assets/data/ethnicity.data.json";
import StudentService from "../../../src/services/StudentService";
import CommonService from "../../../src/services/CommonService";
import WaiverService from "../../../src/services/WaiverService";

import { STATUS_CODE } from "../../constants/ApiConstant";
const Option = Select;

const currentYear = moment().year();

const StudentInfo = (props) => {
  const { step, setStep, selectedCourseList,studentData, setStudentData, totalCost } = props;
  const [form] = Form.useForm();
  const [countriesList, setCountry] = useState([]);
  const [parentStateList, setParentState] = useState([]);
  const [studentStateList, setStudentState] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [addedDocumentTypes, setAddedDocumentTypes] = useState([]);
  const [pageStep, setPageStep] = useState(1);

  useEffect(() => {
    getStudentInfo();
    populateCountry();
  }, []);

  const populateCountry = () => {
    CommonService.getAllCountries().then((res) => {
      setCountry(res.data);
    });
  };

  const passingYear = [];
  for (let i = 2010; i <= currentYear; i++) {
    passingYear.push(i);
  }

  const getStudentInfo = () => {
    const studentId = localStorage.getItem("studenId");
    if (studentId) {
      StudentService.getById(studentId).then((res) => {
        if (res.data.statusCode === STATUS_CODE.NOT_EXISTS) {
          message.warning(`Student does not exist`);
        } else {
          const studentInfo = res.data;
          const parentInfo = res.data.parentInfo;
          const academicInfo = res.data.academicInfo;
          handleParentCountryChange(parentInfo.address.countryId);
          handleStudentCountryChange(parentInfo.address.countryId);
          form.setFieldsValue({
            parentFirstName: parentInfo.firstName,
            parentMiddleName: parentInfo.middleName,
            parentLastName: parentInfo.lastName,
            parentEmailAddress: parentInfo.emailAddress,
            parentMobileNumber: parentInfo.mobileNumber,
            relationshipId: parentInfo.relationshipId,
            occupationId: parentInfo.occupationId,
            parentAddress: parentInfo.address.line1,
            parentCountryId: parentInfo.address.countryId,
            parentStateId: parentInfo.address.stateId,
            parentCity: parentInfo.address.city,
            parentPinCode: parentInfo.address.pinCode,

            firstName: studentInfo.firstName,
            middleName: studentInfo.middleName,
            lastName: studentInfo.lastName,
            emailAddress: studentInfo.emailAddress,
            mobileNumber: studentInfo.mobileNumber,
            ethnicityId: studentInfo.ethnicityId,
            genderId: studentInfo.genderId,
            dateofBirth: moment(studentInfo.dateofBirth),
            address: studentInfo.address.line1,
            countryId: studentInfo.address.countryId,
            stateId: studentInfo.address.stateId,
            city: studentInfo.address.city,
            pinCode: studentInfo.address.pinCode,

            schoolName: academicInfo.schoolName,
            gradeId: academicInfo.gradeId,
            passingYear: academicInfo.passingYear,
            cgpa: academicInfo.cgpa,
          });
        }
      });
    }
  };

  const handleCountryChange = (countryId, setStateFunc, fieldId) => {
    if (parseInt(countryId) > 0) {
      CommonService.getAllStateByCountryId(countryId).then((res) => {
        setStateFunc(res.data);
      });
    }
    setStateFunc([]);
    form.setFieldsValue({
      [fieldId]: "Select State",
    });
  };

  const handleParentCountryChange = (countryId) => {
    handleCountryChange(countryId, setParentState, "parentStateId");
  };

  const handleStudentCountryChange = (countryId) => {
    handleCountryChange(countryId, setStudentState, "stateId");
  };

  const handleAddDocument = () => {
    form.validateFields(["documentId", "documentnumber"]).then((values) => {
      const selectedDocument = documentListData.find(
        (doc) => doc.id === values.documentId
      );
      if (selectedDocument) {
        const selectedDocumentType = selectedDocument.title;
        if (!addedDocumentTypes.includes(selectedDocumentType)) {
          if (values.documentnumber) {
            const newDocument = {
              id: Date.now(),
              documentType: selectedDocumentType,
              documentNumber: values.documentnumber,
            };

            setDocumentList((prevList) => [...prevList, newDocument]);
            setAddedDocumentTypes((prevTypes) => [
              ...prevTypes,
              selectedDocumentType,
            ]);
            message.success(`Document added successfully`);
            form.resetFields(["documentId", "documentnumber"]);
          } else {
            message.error(`Please provide a document number`);
          }
        } else {
          message.error(`Cannot re-add a document`);
        }
      } else {
        message.error(`Please select a document type`);
      }
    });
  };

  const handleDeleteDocument = (recordId) => {
    const documentToDelete = documentList.find((item) => item.id === recordId);
    const documentTypeToDelete = documentToDelete
      ? documentToDelete.documentType
      : null;
    setDocumentList((prevList) =>
      prevList.filter((item) => item.id !== recordId)
    );
    setAddedDocumentTypes((prevTypes) =>
      prevTypes.filter((type) => type !== documentTypeToDelete)
    );
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "Document Number",
      dataIndex: "documentNumber",
      key: "documentNumber",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Popconfirm
          title="Delete the course"
          description="Are you sure to delete this record?"
          onConfirm={() => handleDeleteDocument(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  const rules = {
    firstName: [
      {
        required: true,
        message: "Please enter first name",
        whitespace: true,
      },
    ],
    lastName: [
      {
        required: true,
        message: "Please enter last name",
        whitespace: true,
      },
    ],
    relationship: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (value !== "0" && value !== undefined) {
            return Promise.resolve();
          }
          return Promise.reject("Please select relationship");
        },
      }),
    ],
    dob: [
      {
        required: true,
        message: "Please select date of birth",
      },
    ],
    gender: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (value !== "0" && value !== undefined) {
            return Promise.resolve();
          }
          return Promise.reject("Please select gender");
        },
      }),
    ],
    mobile: [
      {
        required: true,
        message: "Please enter mobile number",
      },
      {
        validator: (_, value) => {
          if (!value || /^[0-9]{10}$/.test(value)) {
            return Promise.resolve();
          } else {
            return Promise.reject("Please enter a valid mobile number");
          }
        },
      },
    ],
    email: [
      {
        required: true,
        message: "Please enter email",
      },
      {
        validator: (_, value) => {
          if (
            !value ||
            /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value)
          ) {
            return Promise.resolve();
          } else {
            return Promise.reject("Please enter a valid email");
          }
        },
      },
    ],
    percentage: [
      {
        validator: (_, value) => {
          if (!value || /^\d+(\.\d+)?$/.test(value)) {
            return Promise.resolve();
          } else {
            return Promise.reject("Please enter a valid percentage");
          }
        },
      },
    ],
  };

  const saveInfo = () => {
    const studentId = localStorage.getItem("studenId");
    form.validateFields().then((values) => {
      //Set Academic Info
      if (values.schoolName || values.gradeId || values.passingYear || values.cgpa) {
        const academicInfo = {
          schoolName: values?.schoolName,
          gradeId: values?.gradeId,
          passingYear: values?.passingYear,
          cgpa: values?.cgpa,
        };
        studentData.academicInfo = academicInfo;
        setStudentData(studentData);
        window.scrollTo(0, 0);
      }

      if (!studentId) {
        StudentService.create(studentData).then((res) => {
          if (res.data.statusCode === STATUS_CODE.SUCCESS) {
            message.success(`Student registered successfully`);
            setStep(step + 1);
          } else if (res.data.statusCode === STATUS_CODE.CONFLICT) {
            message.warning(`Email already exists`);
          } else {
            message.error(`Error in adding`);
          }
        });
      } else {
        StudentService.update(studentId, studentData).then((res) => {
          if (res.data.statusCode === STATUS_CODE.SUCCESS) {
            message.success(`Student registered successfully`);
            setStep(step + 1);
            localStorage.clear();
          } else if (res.data.statusCode === STATUS_CODE.CONFLICT) {
            message.warning(`Email already exists`);
          } else {
            message.error(`Error in updating`);
          }
        });
      }
    });
  };

  const handleNext = (pageStep) => {
    form
      .validateFields()
      .then((values) => {
        //Set Student Info
        if (pageStep === 1) {
          const data = {
            userId: -1,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            emailAddress: values.emailAddress,
            mobileNumber: values.mobileNumber,
            ethnicityId: values.ethnicityId,
            genderId: values.genderId,
            dateofBirth: moment(values.dateofBirth).format("MM/DD/YYYY"),
            password: values.studentPassword,
            statusId: 2,
            addressInfo: {
              line1: values.address,
              stateId: values.stateId,
              city: values.city,
              pinCode: values.pinCode,
            },
          };
          setStudentData(data);
          window.scrollTo(0, 0);
        }

        //Set Parent Info
        if (pageStep === 2) {
          const parentInfo = {
            firstName: values.parentFirstName,
            middleName: values.parentMiddleName,
            lastName: values.parentLastName,
            mobileNumber: values.parentMobileNumber,
            emailAddress: values.parentEmailAddress,
            relationshipId: values.relationshipId,
            password: values.parentPassword,
            address: {
              line1: values.parentAddress,
              stateId: values.parentStateId,
              city: values.parentCity,
              pinCode: values.parentPinCode,
            },
          };
          studentData.parentInfo = parentInfo;
          setStudentData(studentData);
          window.scrollTo(0, 0);
        }

        if (pageStep === 1) {
          const hasWaiverCourse = selectedCourseList.some(course => course.isWaiver === true);
          if (hasWaiverCourse) {
            form
              .validateFields()
              .then((values) => {
                if (pageStep === 1) {
                  const email = values.emailAddress;
                  if (email) {
                    WaiverService.getByEmail(email)
                      .then((res) => {
                        if (res && res.data && res.data.statusCode === 404) {
                          message.warning(`Student is not eligible for waiver / stipend`);
                        } else if (res && res.data && res.status === 200) {
                          setPageStep(pageStep + 1);
                        } else {
                          message.error("Unexpected response from the server.");
                        }
                      })
                      .catch((error) => {
                        console.error("Error:", error);
                        if (error.response && error.response.status === 404) {
                          message.warning(`Student is not eligible for waiver / stipend`);
                        } else {
                          message.error("An error occurred while fetching student information.");
                        }
                      });
                  }
                }
              })
          } else {
            setPageStep(pageStep + 1);
          }
        } else {
          setPageStep(pageStep + 1);
        }
      })
      .catch((info) => {
        message.error("Please enter all required field ");
      });
  };

  const PersonalInfo = () => {
    return (
      <div>
        <h2 className="widget-title text-left">Personal Information</h2>

        <div className="justified p-2 enrollment-form">
          <Form
            layout="vertical"
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={rules.firstName}
                >
                  <Input placeholder="First Name" maxLength={100} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="middleName" label="Middle Name">
                  <Input placeholder="Middle Name" maxLength={100} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={rules.lastName}
                >
                  <Input placeholder="Last Name" maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="dateofBirth"
                  label="Date of Birth"
                  rules={rules.dob}
                >
                  <DatePicker
                    name="dateofBirth"
                    placeholder="MM/DD/YYYY"
                    format="MM/DD/YYYY"
                    className="w-100"
                    disabledDate={(current) =>
                      current && current > moment().endOf("day")
                    }
                    inputReadOnly
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="genderId"
                  label="Gender"
                  rules={rules.gender}
                  required
                >
                  <Select placeholder="Gender" defaultValue="Select Gender">
                    <Option value="0">Select Gender</Option>
                    {genderList.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="mobileNumber"
                  label="Mobile"
                  rules={rules.mobile}
                >
                  <Input
                    placeholder="Mobile Number"
                    maxLength={10}
                    onKeyDown={(event) => {
                      if (
                        event.key !== "Delete" &&
                        event.key !== "Backspace" &&
                        event.key !== "ArrowLeft" &&
                        event.key !== "ArrowRight" &&
                        !/[0-9]/.test(event.key)
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="emailAddress" label="Email" rules={rules.email}>
                  <Input placeholder="Email" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="ethnicityId" label="Ethnicity">
                  <Select placeholder="Ethnicity" defaultValue="Select Ethnicity">
                    <Option value="0">Select Ethnicity</Option>
                    {ethnicityListData.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="studentPassword" label="New Password">
                  <Input type="password" placeholder="********" maxLength={50} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="confirmStudentPassword" label="Confirm Password">
                  <Input type="password" placeholder="********" maxLength={50} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item name="address" label="Address">
                  <Input.TextArea
                    placeholder="Address"
                    rows={4}
                    maxLength={200}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="countryId" label="Country">
                  <Select
                    placeholder="Select Country"
                    defaultValue="Select Country"
                    onChange={handleStudentCountryChange}
                  >
                    <Option value="0">Select Country</Option>
                    {countriesList.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="stateId" label="State">
                  <Select placeholder="Select State" defaultValue="Select State">
                    <Option value="0">Select State</Option>
                    {studentStateList.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="city" label="City">
                  <Input placeholder="City" maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="pinCode" label="PIN Code">
                  <Input
                    placeholder="PIN Code"
                    maxLength={6}
                    onKeyDown={(event) => {
                      if (
                        event.key !== "Delete" &&
                        event.key !== "Backspace" &&
                        event.key !== "ArrowLeft" &&
                        event.key !== "ArrowRight" &&
                        !/[0-9]/.test(event.key)
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  };

  const ParentInfo = () => {
    return (
      <div>
        <h2 className="widget-title text-left">Parent Information</h2>

        <div className="justified p-2">
          <Form
            layout="vertical"
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="parentFirstName"
                  label="First Name"
                  rules={rules.firstName}
                >
                  <Input placeholder="First Name" maxLength={100} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="parentMiddleName" label="Middle Name">
                  <Input placeholder="Middle Name" maxLength={100} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="parentLastName"
                  label="Last Name"
                  rules={rules.lastName}
                >
                  <Input placeholder="Last Name" maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="relationshipId"
                  label="Relationship"
                  rules={rules.relationship}
                  required
                >
                  <Select
                    placeholder="Relationship"
                    defaultValue="Select Relationship"
                  >
                    <Option value="0">Select Relationship</Option>
                    {relationshipListData.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="parentMobileNumber"
                  label="Mobile Number"
                  rules={rules.mobile}
                >
                  <Input
                    placeholder="Mobile Number"
                    maxLength={10}
                    onKeyDown={(event) => {
                      if (
                        event.key !== "Delete" &&
                        event.key !== "Backspace" &&
                        event.key !== "ArrowLeft" &&
                        event.key !== "ArrowRight" &&
                        !/[0-9]/.test(event.key)
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item
                  name="parentEmailAddress"
                  label="Email"
                  rules={rules.email}
                >
                  <Input placeholder="Email" maxLength={50} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="parentPassword" label="New Password">
                  <Input type="password" placeholder="********" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="confirmPassword" label="Confirm Password">
                  <Input type="password" placeholder="********" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item name="parentAddress" label="Address">
                  <Input.TextArea
                    placeholder="Address"
                    rows={4}
                    maxLength={200}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="parentCountryId" label="Country">
                  <Select
                    placeholder="Select Country"
                    defaultValue="Select Country"
                    onChange={(e) => handleParentCountryChange(e)}
                  >
                    <Option value="0">Select Country</Option>
                    {countriesList.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="parentStateId" label="State">
                  <Select placeholder="Select State" defaultValue="Select State">
                    <Option value="0">Select State</Option>
                    {parentStateList.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="parentCity" label="City">
                  <Input placeholder="City" maxLength={100} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="parentPinCode" label="PIN Code">
                  <Input
                    placeholder="PIN Code"
                    maxLength={6}
                    onKeyDown={(event) => {
                      if (
                        event.key !== "Delete" &&
                        event.key !== "Backspace" &&
                        event.key !== "ArrowLeft" &&
                        event.key !== "ArrowRight" &&
                        !/[0-9]/.test(event.key)
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  };

  const AcademicInfo = () => {
    return (
      <div>
        <h2 className="widget-title text-left">Academic Information</h2>

        <div className="justified p-2">
          <Form
            layout="vertical"
            form={form}
            name="advanced_search"
            className="ant-advanced-search-form"
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="gradeId" label="Grade">
                  <Select placeholder="Select Grade" defaultValue="Select Grade">
                    <Option value="0">Select Grade</Option>
                    {gradeData.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="passingYear" label="Passing Year">
                  <Select placeholder="Select Year" defaultValue="Select Year">
                    <Option value="0">Select Year</Option>
                    {passingYear.map((elm) => (
                      <Option key={elm} value={elm}>
                        {elm}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="schoolName" label="School Name">
                  <Input placeholder="School Name" maxLength={100} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <Form.Item name="cgpa" label="Percentage/Grade">
                  <Input placeholder="Percentage" maxLength={5} />
                </Form.Item>
              </Col>
            </Row>

            <Card>
              <div className="pt-4">
                <h5 className="mb-4">Document Detail</h5>
              </div>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item name="documentId" label="Document Type">
                    <Select
                      placeholder="Select Document Type"
                      defaultValue="Select Document Type"
                    >
                      <Option value="0">Select Document Type</Option>
                      {documentListData.map((elm) => (
                        <Option key={elm.id} value={elm.id}>
                          {elm.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item name="documentnumber" label="Document Number">
                    <Input placeholder="Document Number" maxLength={50} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <div className="row text-center">
                    <div className="col-lg-12 col-md-12">
                      <button
                        type="button"
                        className="btn clearfix login-btn width-15 mt-5"
                        data-bs-dismiss="modal"
                        onClick={handleAddDocument}
                      >
                        <span className="btn-wrap">
                          <span className="text-one">+ Add</span>
                          <span className="text-two">+ Add</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <h6>Uploaded Document List</h6>
                  <div className="table-responsive pt-5">
                    <Table
                      dataSource={documentList}
                      columns={columns}
                      pagination={false}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <div className="default-form">
      <div className="title-box">
        <div className="title p-3">
          <h5>Admission</h5>
        </div>

        {pageStep === 1 ? <PersonalInfo /> : null}

        {pageStep === 2 ? <ParentInfo /> : null}

        {pageStep === 3 ? <AcademicInfo /> : null}

        <div className="row">
          <div className="col-lg-6 col-md-6"></div>
          <div className="col-lg-6 col-md-6 text-align-right">
            <Button
              type="primary"
              htmlType="submit"
              className="btn clearfix login-btn width-15 "
              onClick={() => setStep(step - 1)}
            >
              <span className="btn-wrap">
                <span className="text-one p-1">Back</span>
                <span className="text-two p-1">Back</span>
              </span>
            </Button>

            {pageStep > 1 ? (
              <Button
                type="primary"
                htmlType="submit"
                className="btn clearfix login-btn width-15"
                onClick={() => {
                  setPageStep(pageStep - 1);
                  window.scrollTo(0, 0);
                }}
              >
                <span className="btn-wrap">
                  <span className="text-one p-1">Prev</span>
                  <span className="text-two p-1">Prev</span>
                </span>
              </Button>
            ) : null}
            {pageStep < 3 ? (
              <Button
                type="primary"
                htmlType="submit"
                className="btn clearfix login-btn width-15 "
                onClick={() => handleNext(pageStep)}
              >
                <span className="btn-wrap">
                  <span className="text-one p-1">Next</span>
                  <span className="text-two p-1">Next</span>
                </span>
              </Button>
            ) : null}

            {pageStep === 3 ? (
              <Button
                type="primary"
                htmlType="submit"
                className="btn clearfix login-btn width-15 "
                onClick={() => saveInfo()}
              >
                <span className="btn-wrap">
                  <span className="text-one p-1">Save</span>
                  <span className="text-two p-1">Save</span>
                </span>
              </Button>
            ) : null}
          </div>
        </div>

        {/* Form Button */}
      </div>
    </div>
  );
};

export default StudentInfo;
