import React, { useState } from "react";
import { Modal } from "antd";
import LoginForm from "./loginForm";

const Confirmation = (props) => {
  const [show, setShow] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);

  const GRADE_GROUP = {
    ElementrySchool: 1,
    MiddleSchool: 2,
    HighSchool: 3,
  };

  const handleReturningStudentClick = () => {
    setShowLoginForm(true);
  };

  const handleNewStudentClick = () => {
    setShow(true);
  };

  const openLoginPage = () => {
    setShow(false);
    setShowLoginForm(true);
  };

  return (
    <div className="title-box">
      <div className="title p-3">
        { props.groupId === GRADE_GROUP.ElementrySchool ?
        <h5>Grade Pre K - 5th Students, (Elementary School)</h5>
        :
        props.groupId === GRADE_GROUP.MiddleSchool ?
        <h5>6th - 8th Students, (Middle School)</h5>
        :
        <h5>9th - 12th Students, (High School)</h5>
        }
      </div>
      <div className="form-group text-center">
        <input
          className="btn-default btn-submit"
          type="submit"
          value="Returning Student"
          name="submit"
          onClick={handleReturningStudentClick}
        />

        <input
          className="btn-default btn-submit"
          type="submit"
          value="New Student"
          name="submit"
          onClick={handleNewStudentClick}
        />
      </div>
      <p>
        Returning Students require the parent to login to complete the
        registration.
      </p>

      <Modal
        title="CONFIRMATION"
        className="modalStyle"
        open={show}
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <p>
          {`Have you previously been enrolled as an student (Grade Pre K-5) in a course before ?`}
        </p>

        <div className="row text-align-right">
          <div className="col-lg-12 col-md-12">
            <button
              type="button"
              className="btn btn-three login-btn"
              data-bs-dismiss="modal"
              onClick={() => props.setStep(3)}
            >
              <span className="btn-wrap">
                <span className="text-one">NO</span>
                <span className="text-two">NO</span>
              </span>
            </button>
            <button
              type="button"
              className="btn clearfix login-btn"
              data-bs-dismiss="modal"
              onClick={openLoginPage}
            >
              <span className="btn-wrap">
                <span className="text-one">YES</span>
                <span className="text-two">YES</span>
              </span>
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        title="Login"
        className="modalStyle"
        onCancel={() => setShowLoginForm(false)}
        open={showLoginForm}
        footer={null}
        destroyOnClose={true}
      >
        <LoginForm setStep={props.setStep} />
      </Modal>
    </div>
  );
};

export default Confirmation;
