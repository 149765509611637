const dev = {
    API_ENDPOINT_URL: 'https://ugla-api.azurewebsites.net/api/',
    RAZORPAY_API_ENDPOINT_URL: 'https://stusphere.vercel.app/api/razorpay',
  };
  
  const prod = {
    API_ENDPOINT_URL: 'https://stusphere-api.azurewebsites.net/api/',
    RAZORPAY_API_ENDPOINT_URL: 'https://stusphere.vercel.app/api/razorpay',
  };
  
  const test = {
    API_ENDPOINT_URL: 'https://ugla-api.azurewebsites.net/api/',
    RAZORPAY_API_ENDPOINT_URL: 'https://stusphere.vercel.app/api/razorpay',
  };
  
  const getEnv = () => {
      switch (process.env.NODE_ENV) {
          case 'development':
              return dev
          case 'production':
              return prod
          case 'test':
              return test
          default:
              break;
      }
  }
  
  export const env = getEnv()
  