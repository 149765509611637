import React, { useState } from "react";
import { Select, Checkbox, Modal, Collapse, message } from "antd";
import courseSegmentList from "../../assets/data/course-segment.data.json";
import infoData from "../../assets/data/high-school-course/english-1.data.json";
import SectionTitle from "../../components/ConsultancyElements/SectionTitle";

const TYPE = {
  CORE: "core",
  OPTIONAL: "optional",
};

const GRADE_GROUP = {
  ElementrySchool: 1,
  MiddleSchool: 2,
  HighSchool: 3,
};

const CourseSelection = (props) => {
  const { groupId, courseList } = props;
  const [show, setShow] = useState(false);

  const handleSegmentChange = (item, segmentId) => {
    const updatedGrade = courseList.map((grade) => {
      const updatedCourse = grade.course.map((courseItem) => {
        if (courseItem.id === item.id) {
          return {
            ...courseItem,
            checked: segmentId !== "Select Segment",
            segmentId,
          };
        } else {
          return courseItem;
        }
      });
      return { ...grade, course: updatedCourse };
    });

    props.setCourse(updatedGrade);
  };

  const viewCourseDetails = async (item) => {
    let selectedGrade = "";    
    const fileName = item.fileName;
    
    if (groupId === GRADE_GROUP.HighSchool) {
      selectedGrade = "high-school";
    }

    if (groupId === GRADE_GROUP.MiddleSchool) {
      selectedGrade = "middle-school";
    }

    try {
      const response = await fetch(
        `/course-file/${selectedGrade}/${fileName}.data.json`
      );

      if (response) {
        const fileData = await response.json();
        if (fileData) {
          debugger;
        }
      }
      setShow(true);
    } catch (e) { 
      message.error("Course information doesn't exists");
    }
  };
  const handleCourseSelection = (event, grade, course) => {
    const filteredList = courseList.filter(function (grade) {
      return grade.course.find((course) => course.checked === true);
    });

    if (
      filteredList.length &&
      filteredList.find((item) => item.id !== grade.id)
    ) {
      message.warning("You can't select more than one grade course");
      return false;
    }

    const updatedGrade = courseList.map((item) => {
      const updatedCourse = item.course.map((courseItem) => {
        if (courseItem.id === course.id) {
          return {
            ...course,
            checked: event.target.checked,
            segmentId: event.target.checked ? 3 : "Select Segment",
          };
        } else {
          return courseItem;
        }
      });
      item.course = updatedCourse;
      return item;
    });

    props.setCourse(updatedGrade);
  };

  const setGradeTableContent = (data) => {
    const updatedData = data.map((grade) => {
      let courseList = createGradeCourse(grade, TYPE.CORE);
      courseList.push(createGradeCourse(grade, TYPE.OPTIONAL));

      return {
        key: grade.key,
        label: grade.title,
        children: courseList,
      };
    });

    return updatedData;
  };

  const createGradeCourse = (grade, type) => {
    return grade.course
      .filter((item) => item.type === type)
      .map((course, index) => {
        return (
          <div className="row" key={course.id}>
            {index === 0 ? (
              <div className="col-lg-12 col-md-12 col-sm-12 p-2 justified">
                {type === TYPE.OPTIONAL ? "OPTIONAL" : "CORE"}
              </div>
            ) : null}

            {/* {index === 0 ? (
              <div className="col-lg-12 col-md-12 col-sm-12 justified">
                <Checkbox>Select All</Checkbox>
              </div>
            ) : null} */}
            <div className="col-lg-6 col-md-6 col-sm-6 p-2 justified">
              <Checkbox
                checked={course.checked}
                onChange={(event) =>
                  handleCourseSelection(event, grade, course)
                }
              >
                {course.title}
              </Checkbox>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 p-2 justified">
              <Select
                value={course.segmentId || "Select Segment"}
                className="full-width course-select"
                onChange={(value) => handleSegmentChange(course, value)}
              >
                <Select.Option value="Select Segment">
                  Select Segment
                </Select.Option>
                {courseSegmentList.map((segment) => (
                  <Select.Option key={segment.id} value={segment.id}>
                    {segment.title}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        );
      });
  };

  const getSelectedCoursesKeys = () => {
    const selectedKeys = [];

    courseList.forEach((grade) => {
      grade.course.forEach((course) => {
        if (course.checked) {
          selectedKeys.push(grade.key);
        }
      });
    });

    return selectedKeys;
  };

  return (
    <div className="title-box">
      <div className="title p-3">
        <h5>Course Selection</h5>
      </div>

      <div className="form-group text-center">
        <h6 className="justified">Please select your grade or course below.</h6>
        <br />
        <p className="justified">
          Please click on each grade level to view and select courses for your
          student. Each course selection will default to the full course, but
          you have the option to change that selection to only one segment if
          you prefer (see above for more information on segments).
        </p>
        {groupId === GRADE_GROUP.ElementrySchool ? (
          <h6 className="justified red-color">Cost Per Segment - $ 75</h6>
        ) : null}
        <br />
        <div className="title">
          {groupId === GRADE_GROUP.ElementrySchool ? (
            <Collapse
              accordion
              items={setGradeTableContent(courseList)}
              defaultActiveKey={getSelectedCoursesKeys}
            />
          ) : (
            <div class="row">
              {courseList.map((grade) => (
                <div className="news-block col-lg-4 col-md-4 col-sm-12">
                  <div className="inner-box">
                    <div className="lower-content course-list-height">
                      <div className="author-box">
                        <div class="title">{grade.title}</div>
                      </div>
                      <div className="lower-box justified text-capitalize overflow-scroll-course">
                        <ul>
                          {grade.course.map((course) => (
                            <li key={course.id}>
                              <Checkbox
                                checked={course.checked}
                                onChange={(event) =>
                                  handleCourseSelection(event, grade, course)
                                }
                              ></Checkbox>
                              <span
                                className="school-course-span"
                                onClick={() => viewCourseDetails(course)}
                              >
                                {course.title}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <Modal
        title="COURSE DETAIL"
        className="modalStyle"
        open={show}
        onCancel={() => setShow(false)}
        footer={null}
        maskClosable={false}
        width={800}
      >
        <div className="widget widget_block widget_custom course-info-box">
          <SectionTitle title={infoData.title} />

          <div className="comments-area">
            <ul className="comment-list">
              <li className="comment even thread-even depth-1">
                <div className="commenter-block">
                  <div className="comment-content">
                    <div className="comment-author-name">
                      Prerequisites :
                      <span className="comment-date">
                        {infoData.prerequisites}
                      </span>
                    </div>
                    <div className="comment-author-name">
                      Credits :
                      <span className="comment-date">{infoData.credits}</span>
                    </div>
                    <div className="comment-author-name">
                      Estimated Completion Time :
                      <span className="comment-date">
                        {infoData.estimatedCompletionTime}
                      </span>
                    </div>
                    <div className="comment-author-name">
                      Earliest Start Date :
                      <span className="comment-date">
                        {infoData.earliestStartDate}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <h2 className="widget-title">{infoData.description.title}</h2>

          <div className="sidebar_author">
            <p className="intro">{infoData.description.content}</p>
          </div>

          <h2 className="widget-title">{infoData.topics.title}</h2>

          {infoData.topics.segment.map((segment) => (
            <div className="blog-details pad-0">
              <div className="blog-details-inner">
                <h6>{segment.title}</h6>
                <ul className="point-order">
                  {segment.content.map((content) => (
                    <li>{content}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}

          <h2 className="widget-title">{infoData.gradingPolicy.title}</h2>

          <div className="sidebar_author">
            <p className="intro">{infoData.gradingPolicy.content}</p>
          </div>

          <h2 className="widget-title">{infoData.communications.title}</h2>

          <div className="sidebar_author">
            <p className="intro">{infoData.communications.content}</p>
          </div>

          <h2 className="widget-title">{infoData.requiredMaterials.title}</h2>

          <div className="sidebar_author">
            <p className="intro">{infoData.requiredMaterials.content}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CourseSelection;
